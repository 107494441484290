import { RemixBrowser } from "@remix-run/react";
import * as Sentry from "@sentry/remix";
import { startTransition, StrictMode } from "react";
import { hydrateRoot } from "react-dom/client";

if (process.env.NODE_ENV === "production")
  Sentry.init({
    dsn: "https://9d8191838a287de15269546d30b61874@o4506504363966464.ingest.us.sentry.io/4508259301392384",
  });

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>
  );
});
